import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToSpaced'
})
export class CamelCaseToSpacedPipe implements PipeTransform {
    transform(value: string, dynamicColumns: any[]): string {
        // Find the corresponding Description for the DynamicColumnName
        const dynamicColumn = dynamicColumns?.find(column => column.DynamicColumnName === value);
        
        // Use the Description if found, otherwise, use the original value
        const transformedValue = dynamicColumn ? dynamicColumn.Description : value;

        // Apply the camelCase to spaced transformation
        return transformedValue.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    }
}
