export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token: string;
  UserID?: number;
}


export interface IRole {
  Code?: string;
  Role?: string;
  User?: string;
  Description?: string;
  ID?: number;
  RoleID?: number;
  selected?: boolean;
}

export interface IPermission {
  Code?: string;
  Description?: string;
  ID?: number;
  Permission?: string;
  PermissionID?: string;
    selected?: boolean;
    PermissionTypeID?: number;
}


export function tokenValidation(userDetails, isNotValid?: boolean) {
  if (isNotValid) {
    let token = userDetails?.['AccessToken'];
    let midpoint = Math.ceil(token.length / 2);
    let part1 = token.slice(0, midpoint);
    let part2 = token.slice(midpoint);
    token = part1 + 's' + part2;
    return { ...userDetails, AccessToken: token }
  }
  return userDetails;
}

