import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from './material.module';
import { NgxImageZoomModule } from "ngx-image-zoom";
import { NgSelectModule } from '@ng-select/ng-select';
import { CamelCaseToSpacedPipe } from './pipes/camelCaseToSpaced.pipe';
import { AgmCoreModule } from '@agm/core';
import { HasAccessPipe } from './pipes/hasAccess.pipe';
import { DecimalFixedPipe } from './pipes/decimal-fixed.pipe';

@NgModule({
  declarations: [
    CamelCaseToSpacedPipe,
    HasAccessPipe,
    DecimalFixedPipe,
  
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    NgxImageZoomModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    NgxImageZoomModule,
    CamelCaseToSpacedPipe,
    HasAccessPipe,
    DecimalFixedPipe,
  ]
})
export class SharedModule { }
