<div>
  <!-- Left Sidebar -->
  <!-- <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)"> -->
  <aside id="leftsidebar" class="sidebar" (click)="headerClick($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
      [ngStyle]="{'position': 'relative', 'max-height': listMaxHeight+'px', 'max-width': listMaxWidth+'px', 'padding-left': document.body.classList.contains('submenu-closed') ? '10px' : '0px'}"
      [perfectScrollbar]>

        <!-- Top Most level menu -->
        <li [class.active]="level1Menu === sidebarItem.moduleName && sidebarItem.submenu.length != 0"
          *ngFor="let sidebarItem of sidebarItems" [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'">
          <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
          <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle 
            && isAccessible(sidebarItem)" [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)" class="menu-top"
            ngbTooltip="{{showTooltip() ? sidebarItem.title: ''}}" container="body">
            <i [ngClass]="[sidebarItem.iconType]"
              style="color: white; font-size: 24px; vertical-align: text-bottom;">{{sidebarItem.icon}}</i>
            <span class="hide-menu">{{sidebarItem.title}}
            </span>
            <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
          </a>

          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li [ngClass]="level2Menu === sidebarSubItem.moduleName? 'activeSub':''"
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'">
              <a *ngIf="isAccessible(sidebarSubItem)"
                [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                (click)="callLevel2Toggle($event,sidebarSubItem.moduleName)" [ngClass]="[sidebarSubItem.class]">
                {{sidebarSubItem.title}}
              </a>

              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="level3Menu === sidebarSubsubItem.moduleName? 'activeSubSub':''"
                  [routerLinkActive]="sidebarSubsubItem.submenu.length > 0 ? '' : 'active'">
                  <a *ngIf="isAccessible(sidebarSubsubItem)"
                    [routerLink]="sidebarSubsubItem.submenu.length > 0 ? null : [sidebarSubsubItem.path]"
                    (click)="callLevel3Toggle($event,sidebarSubsubItem.moduleName)"
                    [ngClass]="[sidebarSubsubItem.class]">
                    {{sidebarSubsubItem.title}}
                  </a>
                  <!-- level 3 -->
                  <ul class="ml-menu-2" 
                    *ngIf="sidebarSubsubItem.submenu.length > 0   && activeSubMenu === sidebarSubsubItem.moduleName">
                    <li *ngFor="let sidebarsubsubsubItem of sidebarSubsubItem.submenu"
                      [ngClass]="level4Menu === sidebarsubsubsubItem.moduleName ? 'activeSubSub' : ''"
                      [routerLinkActive]="sidebarsubsubsubItem.submenu.length > 0 ? 'active' : ''">
                      <a *ngIf="isAccessible(sidebarsubsubsubItem)" 
                        [routerLink]="sidebarsubsubsubItem.submenu.length > 0 ? null : [sidebarsubsubsubItem.path]"
                        (click)="callLevel4Toggle($event, sidebarsubsubsubItem.moduleName)"
                        [ngClass]="[sidebarsubsubsubItem.class]" style="margin-left: 18px;">
                        {{sidebarsubsubsubItem.title}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->

  </aside>
  <!-- #END# Left Sidebar -->
</div>