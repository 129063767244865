import { Pipe, PipeTransform } from '@angular/core';
import { IPermission } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'hasAccess'
})

export class HasAccessPipe implements PipeTransform {

  constructor(private authService: AuthService) { }

  transform(accessCode: string): boolean {
    const allowedPermissions = this.authService.userPermissions.getValue() || [];
    const user = this.authService.currentUserValue;
    if (!user?.UserName) return false;
    return (this.authService.unrestrictedUsers.includes(user?.UserName)) || allowedPermissions.some((permission: IPermission) => permission.Code === accessCode);
  }

}
