import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFixed'
})
export class DecimalFixedPipe implements PipeTransform {

  transform(value: number): string {
    if (isNaN(value)) {
      return '0.00';
    }
    return value.toFixed(2);
  }

}
