<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
        <!--          <button mat-icon-button  class="sidemenu-collapse">-->
        <mat-icon style="color: #FFFFFF">menu</mat-icon>
      </button>

      <span *ngIf="!showLogo()" class="logo-name" style="color: white; vertical-align: bottom; padding-left: 50px">{{logo}}</span>

    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon pr-4">
        <li>
          <a routerLink="dashboard/main" style="display: flex; align-items: center;">
              <div style="width: 60px; height: 40px; background-image: url('assets/images/logo-rein.png'); background-size: cover;"></div>
              <span class="logo-name" style="color:rgba(0, 0, 0, 0.726)">{{logo}}</span>
              <div style="width: 80px; height: 50px; margin-left: 5px; background-image: url('assets/images/Nama-315.jpg'); background-size: cover;"></div>
          </a>
      </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Search bar -->
        <li class=" searchbar pr-3">
            <i class="fa fa-search"></i>
              <input #searchMRNo type="text" placeholder="Search Account/Meter Number" (keyup.enter)="updateData($event,searchMRNo.value)">
              
        </li>
        <!-- Full Screen Button -->
        <li class="fullscreen pr-3">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon color="primary">fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <li class="nav-item user_profile" ngbDropdown>
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="assets/images/user.png" class="rounded-circle" width="32" height="32" alt="User">
          </a>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="usernameinLowercase!='medc'">
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2" color="primary">person</mat-icon>{{usernameinLowercase}}
                      </a>
                    </li>
                    <li *ngIf="usernameinLowercase!='medc'">
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2" color="primary">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li *ngIf="usernameinLowercase!='medc'">
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2" color="primary">help</mat-icon>Help
                      </a>
                    </li>
                    <li>
                      <a routerLink="/authentication/signin" onClick="return false;">
                        <mat-icon aria-hidden="false" class="mr-2" color="warn">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
   
    </div>
  
    
  </div>
</nav>
