// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//Test comments

export const environment = {
  production: false,
  batchBaseURL: 'https://mdm-batch-submit-prod.s3.ap-south-1.amazonaws.com/',
  replacementPdfbatchBaseURL: 'https://mdm-batch-submit-prod.s3.ap-south-1.amazonaws.com/AMR/REPLACEMENT/PDF/',
  amrS3BaseUrl: 'https://mdmamr.s3.ap-south-1.amazonaws.com/',
  s3ImageBaseURL: 'https://s3-ap-south-1.amazonaws.com/autosync-storage/',
  s3ImageBaseAmrURL: 'https://s3-ap-south-1.amazonaws.com/mdmamr/',
  uiV2BaseURL: 'https://mdm.apps.console-rsu.rsutilities.com/',
  s3PdfGenURL: 'https://pdfgenerate-tools.apps.rsu.rsutilities.com/',
  apiV2Url: 'https://mdmapi.rsutilities.com/api',
  // apiV2Url: 'https://localhost:44357/api',
  // apiV2Url: 'https://mdmapistage-mdm-amr-staging.apps.rsu.rsutilities.com/api',
  accessKeyId: "AKIAQ7VRB3MBLFISXGMA",
  secretAccessKey: "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",
  region: "ap-south-1",
  bucket: "autosync-storage",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
