
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, skipUntil, switchMap, take } from 'rxjs/operators';
import { IPermission, User } from '../models/user';
import { environment } from 'src/environments/environment';
import { tokenValidation } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<any>(null);
  public currentUser: Observable<any>;
  public userPermissions: BehaviorSubject<IPermission[]> = new BehaviorSubject<IPermission[]>(null);
  public unrestrictedUsers = ['superadmin'];

  constructor(private http: HttpClient,private router:Router) {
    this.currentUser = this.currentUserSubject.asObservable();
    let userDetails = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserSubject = new BehaviorSubject<any>(userDetails);
    
    setTimeout(() => {
      this.checkValidToken().then(data => {
        if (data.length === 0) {
          this.currentUserSubject.next(tokenValidation(userDetails));
        } else {
          this.currentUserSubject.next(tokenValidation(userDetails, false));
        }
      })
    });
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiV2Url}/User/LoginWeb`, {
        UserName: username,
        Password: password,
      }, { observe: 'response' })
      .pipe(
        map((result) => {
          const userData = result.body;
          const token = userData.AccessToken;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(userData));
          localStorage.setItem('userToken', token);
           // Clear user permissions before updating with new permissions
      this.userPermissions.next([]);
      this.userPermissions.next(userData.Permission);
          this.checkValidToken().then(data => {
            if (data.length === 0) {
              this.currentUserSubject.next(tokenValidation(userData));
            } else {
              this.currentUserSubject.next(tokenValidation(userData, false));
            }
          })
          return token;
        })
      );
  }

  logout() {
    // Send a request to the server to invalidate the token
    return this.http.post(`${environment.apiV2Url}/User/Logout`, {}).pipe(
      switchMap(() => {
        // Clear user data in local storage
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userPermissions');
        
        // Clear user permissions and the current user subject
        this.userPermissions.next([]);
        this.currentUserSubject.next(null);
        
        return of({ success: true });
      }),
      catchError((error) => {
        // Handle any errors related to token invalidation, but still clear local storage
        console.error('Error invalidating token:', error);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userPermissions');
        this.userPermissions.next([]);
        this.currentUserSubject.next(null);
        return of({ success: false });
      })
    );
  }
  
  

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.get(`${environment.apiV2Url}/User/WebLoginPasswordChange?oldPassword=${oldPassword}&newPassword=${newPassword}`, { responseType: "text" });
  }

  getUserPermissions() {
    if (this.currentUserValue) {
      this.currentUserSubject
        .pipe(
          take(1),
          switchMap(user =>
            (this.http.get<any>(`${environment.apiV2Url}/UserRight?userID=${user.UserID}`))
          ))
        .pipe(take(1))
        .subscribe(permissions => {
          this.userPermissions.next(permissions);
        })
    }
  }

  async checkValidToken() {
    let error = [];
    // await this.http.get<any>('https://ju2tloroqosil3n6xq3uyjoh4m0ktjla.lambda-url.ap-south-1.on.aws').toPromise().catch(() => { error.push(false) })
    // await this.http.get<any>('https://3fq7ggha5adnui5xkunn3unq640lzjoa.lambda-url.ap-south-1.on.aws').toPromise().catch(() => { error.push(false) });
    // await this.http.get<any>('https://knocblqqjubycfot4ra7dud3zy0xzfzc.lambda-url.ap-south-1.on.aws').toPromise().catch(() => { error.push(false) });
    // await this.http.get<any>('https://4vuu3uszrmxtwk65ecdgtz73hm0yrpui.lambda-url.ap-south-1.on.aws').toPromise().catch(() => { error.push(false) });
    return error;
  }

}

