import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log(err);

        // if (err.status === 401) {
        //   // auto logout if 401 response returned from api
        //   this.authenticationService.logout();
        //   location.reload();
        // }
        var error = '';
        try {
          if (typeof err.error === "string") {
            error = err.error;
          } else if (err.status == 404) {
            error = 'Server Not found';
          } else if (err.status == 0 || err.status == 500) {
            error = 'Internal Server Error';
          } else if (err.error.text) {
            error = err.error.text
          } 
          else if(err.status == 403){
            error = 'Unauthorized Request';
          }
          else if(err.status == 400){
            error = 'Something Went Wrong';
          }
          else {
            error = err.statusText;
          }
        } catch (err) {
          error = 'Something Went Wrong';
        }
        return throwError(error);
      })
    );
  }
}
