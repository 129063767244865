import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { AuthService } from 'src/app/core/service/auth.service';
import { RouteInfo } from './sidebar.metadata';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  level4Menu='';
  isSubMenuActive: boolean = false;
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  routerObj = null;
  currentRoute: string;
  public userRole: string;
  currentUserPermissions = [];
  permittedRoutes: RouteInfo[] = [];
  currentSubMenuLevel: number = 2;
  activeSubMenu: string = '';
  constructor(
    @Inject(DOCUMENT) public document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private el: ElementRef
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        // const currenturl = event.url.split('?')[0];
        // this.level1Menu = currenturl.split('/')[1];
        // this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (!document.body.classList.contains('side-closed')) {
      if (element === this.level1Menu) {
        this.level1Menu = '0';
      } else {
        this.level1Menu = element;
      }
      const hasClass = event.target.classList.contains('toggled');
      if (hasClass) {
        this.renderer.removeClass(event.target, 'toggled');
      } else {
        this.renderer.addClass(event.target, 'toggled');
      }
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }
  submenuClassMap: { [key: string]: string } = {};

  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  
    this.activeSubMenu = (this.activeSubMenu === element) ? '' : element;
    this.isSubMenuActive = !this.isSubMenuActive;
  
  }
  


  
  callLevel4Toggle(event: any, element: any) {
    if (element === this.level4Menu) {
      this.level4Menu = '0';
    } else {
      this.level4Menu = element;
    }
  }
  
    
  ngOnInit() {
    let userDatajsonString = localStorage.getItem('currentUser');
    let userData;
    if (userDatajsonString != null && userDatajsonString != undefined)
      userData = JSON.parse(userDatajsonString);
    if (this.authService.currentUserValue) {
      this.userRole = userData.Designation?.toLowerCase();
      this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
    this.authService.userPermissions.subscribe(data => { this.currentUserPermissions = data });
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  headerClick(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
    // else if (body.classList.contains('submenu-closed')) {
    //     this.renderer.addClass(this.document.body, 'side-closed-hover');
    //     this.renderer.removeClass(this.document.body, 'submenu-closed');
    //   }
  }
 
  public showTooltip() {
    const body = this.elementRef.nativeElement.closest('body');
    return body.classList.contains('submenu-closed', 'side-closed');
  }


  isAccessible(sibarInfo: RouteInfo) {

    /// return all sibbar item if the user role is unrestricted
    if (this.authService.unrestrictedUsers.includes(this.userRole))
      return true

    // checking if the sidebar permission code is in userpermissions array
    if (sibarInfo.submenu.length === 0) {
      if (this.checkUserPermission(sibarInfo?.permissionCode))
        return true;
    }

    /// checking level 3 have permission on 2nd level
    if (this.checkChildIsAccessible(sibarInfo.submenu)) {
      return true;
    }

    /// checking either level2 or level 3 have permission
    if (sibarInfo.class === 'menu-toggle') {

      // checking level 2
      if (this.checkChildIsAccessible(sibarInfo.submenu))
        return true;

      /// checking level 3
      for (let i = 0; i < sibarInfo.submenu.length; i++) {
        if (this.checkChildIsAccessible(sibarInfo.submenu[i].submenu))
          return true;
      }
    }

    return false;
  }

  checkUserPermission(code: string | string[]) {
    if (!code)
      return false;
    return this.currentUserPermissions.some(user => {
      return Array.isArray(code) ? code.includes(user.Code) : user.Code === code;
    });
  }

  checkChildIsAccessible(sidebar: any) {
    return sidebar.some((iterator: RouteInfo) => this.checkUserPermission(iterator?.permissionCode));
  }

}
